/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-return-assign */

import Typography from '@common_typography';
import Link from 'next/link';
import Slider from 'react-slick';
import LeftArrowIcon from '@material-ui/icons/ArrowBack';
import RightArrowIcon from '@material-ui/icons/ArrowForward';
import { getCategories } from '@core_modules/cms/services/graphql';
import { useState } from 'react';
import classNames from 'classnames';
import Thumbor from '@common_image';
import useStyles from '@core_modules/cms/components/cms-renderer/magezon/MagezonCategories/styles';
import Button from '@material-ui/core/Button';

const MagezonCategories = (props) => {
    // prettier-ignore
    const {
        categories, description, line_position,
        link_border_color, link_border_width, link_color,
        link_font_size, link_font_weight, link_hover_color,
        show_line, title, title_align, title_tag,
        showArrow = true,
        autoPlay = false,
        speed = 500,
        autoplaySpeed = 4000,
        xs = 767, sm = 1024, md = 1200,
        storeConfig,
    } = props;

    const showLineClass = show_line ? 'mgz-categories-heading-line' : '';
    const linePosClass = show_line && line_position === 'bottom' ? 'mgz-categories-heading-line--bottom' : '';
    const { data, loading } = getCategories({ ids: categories });
    const styles = useStyles();

    let sliderRef = React.createRef();
    const [slideIndex, setIndex] = useState(0);
    const [count, setCount] = useState(0);

    const renderCategories = (categoryList) => {
        const handleLeftArrow = () => {
            if (slideIndex === 0) {
                sliderRef.slickPrev(categoryList.length - 1);
            } else {
                sliderRef.slickPrev(slideIndex - 1);
            }
        };

        const handleRightArrow = () => {
            if (slideIndex === categoryList.length - 1) {
                sliderRef.slickNext(0);
            } else {
                sliderRef.slickNext(slideIndex + 1);
            }
        };

        const settings = {
            infinite: false,
            slidesToShow: categoryList.length < 3 ? categoryList.length : 3,
            slidesToScroll: 1,
            autoplay: autoPlay,
            speed,
            autoplaySpeed,
            rtl: false,
            arrows: false,
            dots: true,
            swipeToSlide: true,
            initialSlide: 0,
            afterChange: () => setCount(count + 1),
            beforeChange: (current, next) => setIndex(next),
            responsive: [
                {
                    breakpoint: md,
                    settings: {
                        slidesToShow: categoryList.length < 3 ? categoryList.length : 3,
                    },
                },
                {
                    breakpoint: sm,
                    settings: {
                        slidesToShow: data?.categoryList?.length < 3 ? data?.categoryList?.length : 3,
                    },
                },
                {
                    breakpoint: xs,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        };

        return (
            <div className={classNames('carousel', styles.caraousel)}>
                <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
                    {categoryList.map((item) => (
                        <Link href={item.url_path} onClick={(e) => e.stopPropagation()}>
                            <a>
                                <Thumbor
                                    src={item.image || ''}
                                    alt={item.name}
                                    width={307}
                                    height={307}
                                    quality={100}
                                    className={styles.imageSlider}
                                    storeConfig={storeConfig}
                                    magezon
                                />
                            </a>
                        </Link>
                    ))}
                </Slider>
                {showArrow ? (
                    <>
                        <div className={classNames(styles.arrow, styles.leftArrow)} onClick={handleLeftArrow}>
                            <LeftArrowIcon fontSize="inherit" />
                        </div>
                        <div className={classNames(styles.arrow, styles.rightArrow)} onClick={handleRightArrow}>
                            <RightArrowIcon fontSize="inherit" />
                        </div>
                    </>
                ) : null}
                <style jsx global>
                    {`
                    .carousel .slick-track {
                        display: flex;
                    }
                    .carousel .slick-dots {
                        bottom: 0;
                    }
                    .carousel .slick-dots li button:before {
                        opacity: 1;
                        color: #fff;
                    }
                    .carousel .slick-dots li.slick-active button:before {
                        opacity: 1;
                        color: #E89D00;
                    }
                `}
                </style>
            </div>
        );
    };

    const renderCategory = (categoryList) => {
        const settings = {
            infinite: false,
            slidesToShow: categoryList.length < 4 ? categoryList.length : 4,
            slidesToScroll: 1,
            autoplay: autoPlay,
            speed,
            autoplaySpeed,
            rtl: false,
            arrows: false,
            dots: true,
            swipeToSlide: true,
            customPaging: (i) => (
                <a key={i}>
                    <div className="custom-slick-dots" />
                </a>
            ),
            afterChange: () => setCount(count + 1),
            beforeChange: (current, next) => setIndex(next),
            responsive: [
                {
                    breakpoint: md,
                    settings: {
                        slidesToShow: categoryList.length < 3 ? categoryList.length : 3,
                    },
                },
                {
                    breakpoint: sm,
                    settings: {
                        slidesToShow: categoryList.length < 3 ? data?.categoryList.length : 3,
                    },
                },
                {
                    breakpoint: xs,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        };

        return (
            <div className={classNames('carousel-2', styles.caraousel)}>
                <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
                    {categoryList.map((item) => (
                        <div className={styles.segment}>
                            <Link href={item.url_path} onClick={(e) => e.stopPropagation()}>
                                <a>
                                    <Thumbor
                                        src={item.image || '/'}
                                        alt={item.name || ''}
                                        width={307}
                                        height={307}
                                        quality={100}
                                        className={styles.imageSlider}
                                        storeConfig={storeConfig}
                                        magezon
                                    />
                                </a>
                            </Link>
                            <Typography variant="h3" align="left" type="semiBold">
                                {item.name}
                            </Typography>
                            <Typography variant="span" align="left">
                                {item.description}
                            </Typography>
                            <Button variant="contained" className={styles.Btn}>
                                <Link href="/[...slug]" as={`/${item.url_path}`}>
                                    <a>Discover</a>
                                </Link>
                            </Button>
                        </div>
                    ))}
                </Slider>
                <style jsx global>
                    {`
                    .carousel-2 .slick-track {
                        display: flex;
                    }
                    .carousel-2 .custom-slick-dots {
                        width: 6px;
                        height: 6px;
                        background-color: #000;
                        border-radius: 50px;
                    }
                    .carousel-2 .slick-active .custom-slick-dots {
                        background-color: #fff;
                        border: 1.5px solid #9C9C9C;
                        transform: scale(1.7);
                    }
                    .carousel-2 .slick-dots {
                        position: relative;
                    }
                    .carousel-2 .slick-dots li {
                        margin: 0;
                    }
                    .category-slider {
                        margin: 0 calc(0px - (100vw - 100%) / 2)
                    }
                    .category-slider .row {
                        justify-content: center;
                        padding: 30px 0 !important;
                    }
                    .category-slider .row .mgz-column {
                        max-width: 1440px;
                        padding: 0 50px;
                    }
                `}
                </style>
            </div>
        );
    };

    if (loading && !data) return <>Loading</>;

    // const memoizedCategories = useMemo(() => renderCategories(data.categoryList), [data.categoryList]);

    return (
        <>
            <div className="mgz-categories">
                <div className={`mgz-categories-heading ${showLineClass} ${linePosClass}`}>
                    <div className="mgz-categories-heading-title">
                        <Typography variant={title_tag} align={title_align} letter="capitalize" type="semiBold">
                            {title}
                        </Typography>
                    </div>
                    <div className="mgz-categories-heading-description">{description}</div>
                </div>
                <div className="mgz-categories-content">
                    {/* {memoizedCategories} */}
                    {data?.categoryList?.length > 1 && (
                        renderCategories(data?.categoryList)
                    )}
                    {data?.categoryList?.length === 1 && (
                        renderCategory(data?.categoryList[0]?.children)
                    )}
                </div>
            </div>
            <style jsx>
                {`
                    .mgz-categories-heading {
                        text-align: ${title_align};
                        position: relative;
                        margin-bottom: 10px;
                        padding-bottom: 10px;
                    }
                    .mgz-categories-heading-title {
                        background-color: transparent;
                        display: inline-block;
                        position: relative;
                    }
                    .mgz-categories :global(.accordion-details-inner) {
                        display: flex;
                        flex-direction: column;
                        margin: 1px;
                        width: 100%;
                        justify-content: flex-start;
                    }
                    .mgz-categories :global(.mgz-categories-link) {
                        ${link_color ? `color: ${link_color};` : ''}
                        ${link_font_size ? `font-size: ${link_font_size}px;` : ''}
                        ${link_font_weight ? `font-weight: ${link_font_weight};` : ''}
                    }
                    .mgz-categories :global(.mgz-categories-link:hover) {
                        ${link_hover_color ? `color: ${link_hover_color};` : ''}
                    }
                    .mgz-categories :global(.MuiAccordion-root) {
                        border-bottom: ${link_border_width ? `${link_border_width}px` : '1px'} solid
                            ${link_border_color || '#000000'};
                    }
                    .mgz-categories :global(.slick-list) {
                        margin: 0 -10px;
                        display: flex !important;
                        margin-bottom: 10px;
                    }
                    .mgz-categories :global(.slick-slide) {  
                        margin: 0 10px;
                    }
                `}
            </style>
        </>
    );
};

export default MagezonCategories;
