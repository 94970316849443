import { makeStyles } from '@material-ui/core/styles';
import { WHITE, PRIMARY } from '@theme_color';
import { Centering } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    caraousel: {
        width: '100%',
        height: '100%',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
        },
        '& .magezon-slider-button-nav': {
            backgroundColor: 'rgba(255,255,255,0.5)',
            width: '50px',
            height: '50px',
            margin: '12.5px 4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'opacity 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out',
            backdropfilter: 'blur(30px)',
            border: '1px solid #fff',
            borderRadius: '50%',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        '& .magezon-slider-button-nav .MuiSvgIcon-root': {
            color: WHITE,
        },
        '& .magezon-slider-button-nav:hover .MuiSvgIcon-root': {
            color: WHITE,
        },
        '& .magezon-slider-button-nav:hover': {
            cursor: 'pointer',
            border: '1px solid #fff',
            backgroundColor: 'rgba(255,255,255,0.5)',

        },
    },
    dots: {
        zIndex: 2,
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        justifyContent: 'space-arround',
        bottom: 33,
        left: '50%',
        transform: 'translate(-50%, -50%)',
        '&.custom-dots': {
            [theme.breakpoints.up('sm')]: {
                bottom: '50px',
                textAlign: 'left',
                left: '10%',
                columnGap: '10px',
            },
            [theme.breakpoints.down('sm')]: {
                bottom: 21,
                alignItems: 'center',
            },
        },
    },
    dotsItem: {
        width: 7,
        height: 7,
        borderRadius: '50%',
        backgroundColor: WHITE,
        margin: 5,
        cursor: 'pointer',
        '&.slider-dot': {
            width: '8px !important',
            height: '8px !important',
            [theme.breakpoints.down('sm')]: {
                width: '6px !important',
                height: '6px !important',
            },
        },
    },
    dotActive: {
        backgroundColor: PRIMARY,
        width: 10,
        height: 10,
        '&.slider-dot-active': {
            width: '8px !important',
            height: '8px !important',
            backgroundColor: 'rgb(232, 157, 0) !important',
            transform: 'scale(1.6)',
            border: '2px solid rgb(255, 255, 255)',
            [theme.breakpoints.down('sm')]: {
                border: '1px solid rgb(255, 255, 255)',
                transform: 'none',
            },
        },
    },
    hide: {
        display: 'none',
    },
    imageSlider: {
        display: 'flex',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
        },
    },
    imageSliderAuto: {
        [theme.breakpoints.up('sm')]: {
            width: 'auto !important',
        },
    },
    thumborContainer: {
        backgroundColor: '#eee',
        width: '100%',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
            paddingTop: 0,
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: WHITE,
        },
        [theme.breakpoints.down('sm')]: {
            '& picture': {
                '& > img': {
                    height: 'auto !important',
                },
            },
        },
    },
    thumborImage: {
        width: '100%',
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
            position: 'unset',
        },
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
    arrow: {
        fontSize: '1.5rem',
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'absolute',
        ...Centering,
        padding: 10,
        borderRadius: 5,
        textAlign: 'center',
        paddingLeft: 10,
        top: 'calc(50% - 1rem)',
        width: 40,
        height: 40,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: PRIMARY,
            color: WHITE,
        },
    },
    leftArrow: {
        left: 20,
    },

    rightArrow: {
        right: 20,
    },
    productVideo: {
        backgroundColor: '#eee',
        width: '100%',
        position: 'relative',
        paddingTop: '116%',
        marginTop: '0',
        '& iframe': {
            position: 'absolute',
            top: '0',
            height: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
            paddingTop: 0,
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: WHITE,
            '& iframe': {
                position: 'absolute',
                top: '0',
                height: '600px',
            },
        },
    },
}));

export default useStyles;
