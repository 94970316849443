/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import CmsPage from '@core_modules/cms/pages/default';
import useStyles from '@core_modules/home/pages/default/components/style';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import * as React from 'react';

const BannerSlider = dynamic(() => import('@core_modules/home/pages/default/components/Banner'));
const FeaturedProducts = dynamic(() => import('@core_modules/home/pages/default/components/FeaturedProducts'));
const CategoryList = dynamic(() => import('@core_modules/home/pages/default/components/CategoryList'));

const Content = (props) => {
    const styles = useStyles();
    let useCmsPage = {};

    const {
        cmsHome, homePageConfig, storeConfig: config, ...other
    } = props;
    let storeConfig = config;
    const useCms = storeConfig?.pwa?.use_cms_page_enable;

    if (homePageConfig && homePageConfig.storeConfig && homePageConfig.storeConfig.pwa) {
        storeConfig = {
            ...config,
            pwa: {
                ...config.pwa,
                ...homePageConfig.storeConfig.pwa,
                home_slider_desktop_height: '0',
                home_slider_desktop_width: '0',
                home_slider_mobile_height: '0',
                home_slider_mobile_width: '0',
                banner_in_home: true,
            },
        };
        useCmsPage = {
            enable: storeConfig.pwa.use_cms_page_enable,
            identifier: storeConfig.pwa.use_cms_page_identifier,
        };
    }

    const logoUrl = `${props.storeConfig.secure_base_media_url}logo/${props.storeConfig.header_logo_src}`;

    let content = '';

    if (homePageConfig && useCmsPage && useCmsPage.enable) {
        content = (
            <>
                <CmsPage
                    onlyCms
                    slug={[useCmsPage.identifier]}
                    withLayoutHeader
                    withLayoutFooter
                    withCmsTitle={false}
                    {...other}
                    storeConfig={storeConfig}
                    pageConfig={cmsHome}
                />
            </>
        );
    } else {
        content = (
            <>
                <BannerSlider {...other} storeConfig={storeConfig} />
                <FeaturedProducts {...other} storeConfig={storeConfig} />
                <CategoryList {...other} storeConfig={storeConfig} />
            </>
        );
    }

    return (
        <div className={useCms ? styles.cmsContainer : styles.container}>
            {props.storeConfig && props.storeConfig.pwa && props.storeConfig.pwa.mobile_navigation !== 'burger_menu' && (
                <div className={classNames(styles.header)}>
                    <div className={classNames(styles.logo, 'hidden-desktop')}>
                        <img src={logoUrl} alt="logo" className={styles.imgLogo} />
                    </div>
                </div>
            )}
            {content}
        </div>
    );
};

export default Content;
