/* eslint-disable no-plusplus */
import { getCategoryByName, getProduct, getSellerByName } from '@core_modules/theme/services/graphql';
import { useTranslation } from '@i18n';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Router from 'next/router';
import getPath from '@root/core/helpers/getPath';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    searchHeader: {
        backgroundColor: '#fff',
        borderRadius: '4px',
        maxWidth: 422,
        width: '100%',
        '& .MuiAutocomplete-endAdornment': {
            display: 'none',
        },
        '& .MuiFormLabel-root': {
            display: 'none',
        },
        '& .MuiInput-underline': {
            '&:before': {
                display: 'none',
            },
            '&:after': {
                display: 'none',
            },
        },
        '& .MuiInputBase-input': {
            padding: '10px 19px !important',
        },
        '& .MuiInput-root': {
            margin: '0 !important',
            paddingRight: '0 !important',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: 400,
        },
    },
}));

let globalTimeout = null;

const generateItemData = ({
    product, category, seller, enableMultiseller, landing,
}) => {
    const result = [];
    if (product) {
        for (let index = 0; index < product.items.length; index++) {
            const element = product.items[index];
            const prod = {
                id: element.id,
                name: element.name,
                seller_name: element?.seller?.seller_name,
                url_key: element.url_key,
                position: index,
                small_image: element.small_image,
                price_tiers: element.price_tiers,
                price_range: element.price_range,
                type: 'product',
            };
            result.push(prod);
        }
    }
    if (category) {
        for (let index = 0; index < category.length; index++) {
            const element = category[index];
            const cat = {
                id: element.id,
                name: element.name,
                url_key: element.url_path,
                breadcrumbs: element.breadcrumbs,
                position: index,
                type: 'category',
            };
            result.push(cat);
        }
    }

    if (landing) {
        for (let index = 0; index < landing.length; index++) {
            const element = landing[index];
            const land = {
                id: element.id,
                name: element.name,
                link: element.link,
                breadcrumbs: element.breadcrumbs,
                position: index,
                type: 'landing',
            };
            result.push(land);
        }
    }
    if (enableMultiseller) {
        for (let index = 0; index < seller.length; index++) {
            const element = seller[index];
            const sell = {
                additional_info: element.additional_info,
                city: element.city,
                address: element.address,
                description: element.description,
                id: element.id,
                latitude: element.latitude,
                logo: element.logo,
                longitude: element.longitude,
                name: element.name,
                status: element.status,
                position: index,
                type: 'seller',
            };
            result.push(sell);
        }
    }
    return result;
};

export default function ComboBox(props) {
    const {
        placeholder, handleSearch, dataSearch, setValue, OptionsItem, forcePopupIcon = true, maxHeight = '70vh', storeConfig, ...others
    } = props;
    const styles = useStyles();
    const { t } = useTranslation(['common']);
    // const [item, setItem] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    // const [close, setClose] = React.useState(false);
    const [search, setSearch] = React.useState('');

    const enableMultiseller = storeConfig.enable_oms_multiseller === '1';

    const [actGetProduct, { loading, data }] = getProduct(search);

    const [actGetCategory, { data: dCategory, loading: dLoading }] = getCategoryByName(search);

    const [actGetSeller, { data: dSeller }] = getSellerByName(search);

    let itemData = [];

    const isLoadingFinish = enableMultiseller && data && dCategory && dSeller && !loading && !dLoading;

    if (isLoadingFinish) {
        itemData = generateItemData({
            product: data.products, category: dCategory.categoryList, seller: dSeller.getSeller, enableMultiseller,
        });
    }
    // if (enableMultiseller && data && dCategory && dSeller && !open && !loading) {
    //     itemData = generateItemData(data.products, dCategory.categoryList, dSeller.getSeller, enableMultiseller);
    // } else if (!enableMultiseller && data && dCategory && !open && !loading) {
    //     itemData = generateItemData(data.products, dCategory.categoryList, enableMultiseller);
    // }

    if (!search) {
        itemData = generateItemData({ landing: dataSearch?.vesMenu?.items });
    }

    // React.useEffect(() => {
    //     if (itemData.length > 0) {
    //         setItem(itemData);
    //         if (!close) {
    //             setOpen(true);
    //         }
    //     }
    // }, [itemData.length]);

    const startAutocomplete = (e) => {
        if (e.type === 'blur') {
            setSearch('');
            setOpen(false);
        }

        if (e.type === 'keypress') {
            if (e.key === 'Enter') {
                setOpen(false);
                handleSearch(e);
            }
        }

        if (e.type === 'change') {
            const val = encodeURI(e.target.value);
            setValue(val);

            if (globalTimeout) {
                clearTimeout(globalTimeout);
            }
            globalTimeout = setTimeout(async () => {
                setSearch(val);
                actGetProduct();
                actGetCategory();
                actGetSeller();
            }, 500);
        }
    };

    return (
        <Autocomplete
            className={styles.searchHeader}
            id="combo-box-demo"
            options={itemData}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.name === value.name}
            forcePopupIcon={forcePopupIcon}
            openOnFocus={false}
            open={open}
            freeSolo
            ListboxProps={{ style: { maxHeight, overflow: 'auto' } }}
            renderOption={(option) => <OptionsItem {...option} />}
            renderInput={(params) => (
                <TextField
                    id="standard-basic"
                    placeholder={placeholder || t('common:title:search')}
                    margin="normal"
                    onFocus={() => {
                        if (!search) {
                            setOpen(true);
                        }
                    }}
                    {...params}
                />
            )}
            onInputChange={startAutocomplete}
            onBlur={startAutocomplete}
            onKeyPress={startAutocomplete}
            onChange={(e, value) => {
                if (value) {
                    const sharedProp = {
                        name: value?.name || '',
                        small_image: value?.small_image || {},
                        price: value?.price_range ? { priceRange: value.price_range, priceTiers: value.price_tiers || [] } : {},
                    };

                    // setOpen(false);
                    // setClose(true);

                    if (value.type === 'seller') {
                        Router.push(
                            {
                                pathname: '/[...slug]',
                                query: {
                                    productProps: JSON.stringify(sharedProp),
                                },
                            },
                            `/seller/${value.id}`,
                        );
                    } else if (value.type === 'landing') {
                        const resultGetPath = getPath(value.link);
                        Router.push(
                            {
                                pathname: '/[...slug]',
                                query: {
                                    productProps: JSON.stringify(sharedProp),
                                },
                            },
                            resultGetPath,
                        );
                    } else if (value.type === 'product' || value.type === 'category') {
                        Router.push(`/${value.url_key}`);
                    } else {
                        Router.push(`/catalogsearch/result?q=${value}`);
                    }
                }
            }}
            // onClose={() => {
            //     setClose(true);
            //     setOpen(false);
            // }}
            {...others}
        />
    );
}
