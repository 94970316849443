import { makeStyles } from '@material-ui/core/styles';
import { CreateMargin } from '@theme_mixins';
import { BORDER_COLOR, WHITE } from '@theme_color';

const useStyles = makeStyles(() => ({
    container: {
        '&.MuiFormControl-root': {
            width: '100%',
            height: '100%',
            maxHeight: 100,
            ...CreateMargin(0, 0, 20, 0),
        },
        '& .MuiInputBase-root': {
            outline: `1px solid ${BORDER_COLOR}`,
            borderRadius: '6px',
            '& .Mui-disabled': {
                opacity: '0.8',
                backgroundColor: '#F8FAFC',
            },
        },
        '& .MuiInputBase-input': {
            background: '#FFF',
            display: 'flex',
            padding: '10px 14px',
            alignItems: 'center',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '24px',
            borderRadius: '6px',
            height: 'auto',
        },
        '& .MuiInput-underline:before': {
            border: 'none !important',
        },
        '& .MuiInput-underline:after': {
            border: 'none',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: 'none !important',
            height: '100%',
            borderRadius: '6px',
        },
        '& label + .MuiInput-formControl': {
            marginTop: 26,
            backgroundColor: WHITE,
        },
        '& .MuiInputLabel-shrink': {
            transform: 'none',
            lineHeight: '20px',
            marginBottom: 6,
        },
    },
    label: {
        '&.MuiFormLabel-root': {
            textTransform: 'capitalize',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '20px',
            marginBottom: 6,
        },
        '& .MuiFormLabel-asterisk': {
            color: '#FF0404',
        },
    },
    phoneInput: {
        padding: '10px 14px !important',
        marginTop: '26px !important',
        backgroundColor: `${WHITE} !important`,
        outline: `1px solid ${BORDER_COLOR}`,
        border: 'none !important',
        borderRadius: '6px',
        '& .PhoneInputInput': {
            backgroundColor: `${WHITE} !important`,
            padding: '0 !important',
            lineHeight: '24px',
            border: 'none',
        },
        '& .PhoneInputCountry': {
            display: 'none',
        },
    },
}));

export default useStyles;
