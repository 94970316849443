import makeStyles from '@material-ui/core/styles/makeStyles';
import { CreatePadding, FlexColumn, FlexRow } from '@theme_mixins';

export default makeStyles((theme) => ({
    container: {
        ...FlexColumn,
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        height: '35vh',
        ...CreatePadding(10, 10, 10, 10),
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        position: 'relative',
        gap: '10px',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    root: {
        background: 'transparent !important',
    },
    title: {
        margin: 0,
    },
    btnCancel: {
        width: '75%',
        [theme.breakpoints.up('sm')]: {
            width: 320,
        },
    },
    btnShare: {
        ...FlexRow,
        gap: '10px',
        marginRight: 10,
    },
}));
