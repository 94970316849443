import makeStyles from '@material-ui/core/styles/makeStyles';
import { FlexRow } from '@theme_mixins';
import { PRIMARY } from '@theme_color';

export default makeStyles(() => ({
    container: {
        ...FlexRow,
        columnGap: '3px',
    },
    iconBtn: {
        marginRight: 0,
        padding: 0,
    },
    icon: {
        color: '#FFD900',
        '&:hover': {
            color: `${PRIMARY} !important`,
        },
    },
    iconActive: {
        color: '#FFD900',
    },
}));
