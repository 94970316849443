import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    container: {
        borderRadius: 100,
    },
    fullWidth: {
        width: '100%',
        margin: '0',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 375,
        },
    },
    loadRoot: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    centerAlign: {
        textAlign: 'center',
    },
    leftAlign: {
        textAlign: 'left',
    },
    rightAlign: {
        textAlign: 'right',
    },
    wrapper: {
        width: '100%',
        position: 'relative',
        '& .MuiButtonBase-root': {
            boxShadow: 'none',
        },
        '& .MuiButton-root': {
            display: 'inline-flex',
            padding: '10px 40px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            borderRadius: '6px',
        },
        '& .MuiButton-root .MuiButton-label > span': {
            margin: 0,
        },
        '& .MuiButton-label': {
            fontSize: '14px',
            lineHeight: '20px',
            textTransform: 'capitalize',
        },
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default useStyles;
