/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import classNames from 'classnames';
import Typography from '@common_typography';
import ButtonQty from '@common_buttonqty';
import Select from '@common_select';
import Link from 'next/link';
import { CtqQuoteListAddProduct } from '@core_modules/product/services/graphql';
import { useRouter } from '@root/node_modules/next/router';

import useStyles from '@plugin_optionitem/components/Footer/style';

const Button = dynamic(() => import('@common_button'), { ssr: true });

const ConfigurableView = (props) => {
    const {
        // loading,
        disabled,
        showQty = true,
        // handleAddToCart,
        qty,
        setQty,
        t,
        // showAddToCart = true,
        customStyleBtnAddToCard = '',
        // labelAddToCart = '',
        maxQty = 10000,
        customButton,
        customQty = false,
        freeItemsData,
        moduleCtq,
        itemId,
        isLogin,
        selected,
        dataSpec,
        handleSelect,
        storeConfig,
    } = props;

    const styles = useStyles();
    const [loadingAddQuote, setLoadingQuote] = useState(false);
    const [addProductQuote, addQuote] = CtqQuoteListAddProduct();
    const [options, setOptions] = useState([]);
    const [selectedNote, setNotes] = useState(null);
    const [specFormId, setSpecFormId] = React.useState(0);

    const router = useRouter();
    const slug = router?.query?.slug[0];

    const localMaxQty = storeConfig?.max_sale_qty ? storeConfig.max_sale_qty : maxQty;

    React.useEffect(() => {
        setLoadingQuote(addQuote.loading);
    }, [addQuote.loading]);

    useEffect(() => {
        const localOptions = [];
        let itemObj;

        if (!dataSpec) return;

        dataSpec.getSpecFormProduct.items.forEach((item) => {
            itemObj = {
                label: item.title,
                value: item.id,
                notes: item.customizable_options,
            };
            localOptions.push(itemObj);
        });
        setOptions(localOptions);
    }, [dataSpec]);

    useEffect(() => {
        let noteObj;

        if (options) {
            const findItems = options.find((items) => items.value === selected);
            if (findItems) {
                noteObj = {
                    title: findItems.label,
                    notes: findItems.notes,
                };
                setSpecFormId(findItems.value);
                setNotes(noteObj);
            }
        }
    }, [selected, options]);

    const addToQuote = async () => {
        addProductQuote({
            variables: {
                productId: itemId,
                quantity: qty,
                notes: JSON.stringify(selectedNote),
                specification_form_id: specFormId,
            },
            context: {
                request: 'internal',
            },
        }).then(() => {
            window.reloadQuoteQty = true;
            window.toastMessage({
                open: true,
                text: t('product:quote:successAdd'),
                variant: 'success',
            });
        }).catch((e) => {
            const originalError = e.message.includes(':') ? e.message.split(':')[1] : e.message;
            window.toastMessage({
                open: true,
                text: originalError || t('product:quote:failedAdd'),
                variant: 'error',
            });
        });
    };

    const handleAddToQuote = async () => {
        if (selectedNote) {
            addToQuote();
        } else {
            window.toastMessage({
                open: true,
                text: t('product:quote:selectOptions'),
                variant: 'error',
            });
        }
    };

    if (customButton) {
        return customButton;
    }

    return (
        <div>
            {isLogin === 1 && (
                <div className={classNames(styles.specForm, !options?.length ? 'no-options' : '')}>
                    {options?.length ? (
                        <>
                            <Select
                                name="specification_form"
                                label="Choose Specification Form"
                                onChange={handleSelect}
                                value={selected}
                                options={options}
                            />
                            <Typography variant="span">Or</Typography>
                        </>
                    ) : (
                        <>
                            <Typography size="14" letter="capitalize" type="semiBold" variant="label">
                                Choose Specification Form
                            </Typography>
                            <Typography variant="span">Or</Typography>
                        </>
                    ) }

                    <Button
                        className={classNames(styles.btnCreateSpecForm)}
                        color="primary"
                    >
                        <Typography align="center" type="bold" letter="uppercase" color="white" variant="inherit">
                            <Link href={`/customer/addnew?from_detail=true&create=true&from_path=${slug}`}>Create</Link>
                        </Typography>
                    </Button>
                </div>
            )}
            {showQty && (
                <div className={classNames(styles.qty, 'product-OptionItem-qty')}>
                    <Typography type="bold" variant="span">
                        {t('common:title:qty')}
                    </Typography>
                    <ButtonQty value={qty} onChange={setQty} max={customQty ? freeItemsData.quantity : localMaxQty} disabled={disabled} />
                </div>
            )}
            {/* {showAddToCart && (
                <div className={styles.btnAddToCardContainer}>
                    <Button
                        id="plugin-addToCart-btn"
                        className={classNames(styles.btnAddToCard, customStyleBtnAddToCard)}
                        color="primary"
                        onClick={handleAddToCart}
                        loading={loading}
                        disabled={disabled}
                    >
                        <Typography align="center" type="bold" letter="uppercase" color="white" variant="inherit">
                            {labelAddToCart || t('product:addToCart')}
                        </Typography>
                    </Button>
                </div>
            )} */}
            {isLogin && moduleCtq && moduleCtq.CtqIsEnabled && moduleCtq.CtqIsEnabled.is_enabled ? (
                <div className={styles.btnAddToCardContainer}>
                    <Button
                        id="plugin-addToQuote-btn"
                        className={classNames(styles.btnAddToCard, customStyleBtnAddToCard)}
                        color="primary"
                        onClick={handleAddToQuote}
                        loading={loadingAddQuote}
                    >
                        <Typography align="center" type="bold" letter="uppercase" color="white" variant="inherit">
                            {t('product:quote:addToQuote')}
                        </Typography>
                    </Button>
                </div>
            ) : null}
        </div>
    );
};

export default ConfigurableView;
