import { makeStyles } from '@material-ui/core/styles';
import { Centering } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    caraousel: {
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
        },
    },
    imageSlider: {
        objectFit: 'cover',
        aspectRatio: '1 / 1',
    },
    arrow: {
        fontSize: '2.5rem',
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'absolute',
        ...Centering,
        padding: 10,
        borderRadius: '50%',
        textAlign: 'center',
        paddingLeft: 10,
        top: '50%',
        width: 50,
        height: 50,
        cursor: 'pointer',
        backdropFilter: 'blur(30px)',
        border: '1px solid #FFFFFF',
        '& .MuiSvgIcon-root': {
            color: '#fff',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    leftArrow: {
        left: '35px',
    },

    rightArrow: {
        right: '35px',
    },
    segment: {
        display: 'flex !important',
        flexDirection: 'column',
        gap: '10px',
        alignItems: 'flex-start',
        '& .MuiButton-contained': {
            backgroundColor: '#E89D00 !important',
            '& a': {
                color: '#fff !important',
            },
            '&:hover': {
                backgroundColor: '#E89D00 !important',
            },
        },
    },
    Btn: {
        marginTop: '10px',
        padding: '7px 40px',
        borderRadius: 0,
        '& a': {
            textTransform: 'capitalize',
            fontWeight: 'normal',
        },
    },
}));

export default useStyles;
