import IconButton from '@material-ui/core/IconButton';
// import Star from '@material-ui/icons/Star';
// import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import useStyles from '@common_ratingstar/style';

const RatingStar = ({
    value = 1,
    maxvalue = 5,
    onChange = () => {},
    disabled = true,
    sizeIcon = 14,
}) => {
    const styles = useStyles();
    const icon = [];
    // const styleIcon = {
    //     fontSize: sizeIcon,
    // };

    const star = '/assets/img/icons/star-fill.svg';
    const starOutlined = '/assets/img/icons/star-outlined.svg';

    for (let ind = 1; ind <= maxvalue; ind += 1) {
        if (ind <= value) {
            icon.push(
                <IconButton
                    className={styles.iconBtn}
                    key={ind}
                    disabled={disabled}
                    onClick={() => onChange(ind)}
                >
                    {/* <Star style={styleIcon} className={styles.iconActive} /> */}
                    <img 
                        src={star}
                        width={sizeIcon}
                        height={sizeIcon}
                        className={styles.iconActive}
                        alt='rating'
                    />
                </IconButton>,
            );
        } else {
            icon.push(
                <IconButton
                    className={styles.iconBtn}
                    key={ind}
                    disabled={disabled}
                    onClick={() => onChange(ind)}
                >
                    {/* <StarBorderOutlinedIcon style={styleIcon} className={styles.icon} /> */}
                    <img 
                        src={starOutlined}
                        className={styles.icon}
                        width={sizeIcon}
                        height={sizeIcon}
                        alt='rating'
                    />
                </IconButton>,
            );
        }
    }
    return (
        <div className={styles.container}>{icon.map((Item) => Item)}</div>
    );
};

export default RatingStar;
