import makeStyles from '@material-ui/core/styles/makeStyles';
import { WHITE, RED, BLACK } from '@theme_color';
import {
    CenterAbsolute,
    FlexColumn,
    CreateMargin,
} from '@theme_mixins';

export default makeStyles((theme) => ({
    btnAddToCardContainer: {
        marginBottom: 25,
    },
    btnAddToCard: {
        [theme.breakpoints.down('sm')]: {
            ...CenterAbsolute,
        },
        [theme.breakpoints.up('sm')]: {
            width: 316,
            float: 'left',
            maxWidth: '95%',
        },
        ...CreateMargin(0, 8, 0, 0),
        width: '100%',
        height: 41,
        bottom: 0,
        left: 0,
        opacity: 'none',
        color: WHITE,
        borderRadius: 100,
    },
    textBtnAddToCard: {
        fontSize: 16,
        color: `${WHITE} !important`,
    },
    error: {
        color: RED,
    },
    qty: {
        [theme.breakpoints.down('sm')]: {
            ...CreateMargin(0, 15, 30, 15),
            alignItems: 'center',
        },
        ...CreateMargin(0, 15, 30, 0),
        ...FlexColumn,
    },
    specForm: {
        display: 'flex',
        alignItems: 'flex-end',
        margin: '20px 0px',
        '& .MuiFormControl-root': {
            marginRight: '20px',
            marginBottom: 0,
            width: '50%',
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            '& > *': {
                width: 'auto',
            },
        },
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
            '& > div': {
                width: '100% !important',
                margin: '10px 0 10px 0 !important',
            },
            '& > span': {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
            },
        },
        '&.no-options': {
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& .MuiButtonBase-root': {
                marginLeft: 0,
            },
        },
    },
    btnCreateSpecForm: {
        width: 'auto',
        height: 41,
        bottom: 0,
        left: 0,
        color: WHITE,
        borderRadius: 100,
        padding: 0,
        float: 'left',
        marginLeft: 10,
        [theme.breakpoints.down('xs')]: {
            float: 'none',
            marginLeft: 0,
            marginBottom: 20,
        },
        '& span': {
            display: 'block',
            margin: 0,
        },
        '& a': {
            color: `${WHITE} !important`,
            display: 'block',
            padding: '0 25px',
            height: 41,
            lineHeight: '41px',
        },
        '&:hover': {
            '& a': {
                color: `${BLACK} !important`,
            },
        },
    },
}));
