import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    deleteBtn: {
        padding: 5,
        margin: 8,
        '& .MuiSvgIcon-root': {
            '&:hover': {
                color: '#e74c3c !important',
            },
        },
    },
}));

export default useStyles;
